import '@fortawesome/fontawesome-free/css/all.min.css'
import '@factry/bulma'
import frenchkiss from 'frenchkiss'
import { settings as operationSettings } from '@factry/operations-manager'
import { settings as orderSettings } from '@factry/order-overview'
import { settings as translationSettings } from '@factry/translation-manager'
import { initialize } from '@factry/base'
import App from './App.svelte'

operationSettings.helioscreen = false
orderSettings.helioscreen = false

frenchkiss.locale('nl')

translationSettings.canEditDefaultLocale = true

initialize({
  defaultLocale: 'nl',
  availableLocales: ['fr', 'nl'],
  basePath: import.meta.env.VITE_BASE_PATH,
  onLogin: () => {
    window.location.hash = '/operations'
  },
  test: import.meta.env.VITE_TEST,
  versions: import.meta.env.VITE_VERSIONS,
  organization: 'copaco',
})

const app = new App({
  target: document.body,
})

export default app

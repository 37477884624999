<script>
  import Base from '@factry/base'
  import EquipmentManager from '@factry/equipment-manager'
  import TeamRegistration from '@factry/team-manager'
  import DowntimeManager from '@factry/downtime-manager'
  import LoomsSetup from '@factry/looms-setup'
  import HistorianManager from '@factry/historian-manager'
  import OrderManager from '@factry/order-manager'
  import MaterialManager from '@factry/material-manager'
  import MessageQueue from '@factry/message-queue-manager'
  import OperationsManager from '@factry/operations-manager'
  import OrderOverview from '@factry/order-overview'
  import ReportManager from '@factry/report-manager'
</script>

<Base
  options={[
    OperationsManager, DowntimeManager, LoomsSetup, OrderOverview,
    EquipmentManager, TeamRegistration, MessageQueue, HistorianManager,
    MaterialManager, OrderManager, ReportManager
  ]}
/>
